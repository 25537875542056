import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import '../css/style.css';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Games = () => {
    const [games, setGames] = useState([]);
    const [limit, setLimit] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        fetchGames(limit);
    }, [limit]);

    const fetchGames = (limit) => {
        fetch(`http://api.fitsgames.com/api/games?limit=${limit}&start=1`)
            .then(response => response.json())
            .then(data => {
                const existingTitles = new Set(games.map(game => game.title.toLowerCase()));

                // Find duplicates (titles already in the state)
                const duplicates = data.filter(game => existingTitles.has(game.title.toLowerCase()));

                // Log duplicate games
                if (duplicates.length > 0) {
                    console.log("Duplicate Titles Found:", duplicates.map(game => game.title));
                }

                // Filter only unique games
                const uniqueGames = data.filter(game => !existingTitles.has(game.title.toLowerCase()));

                setGames(prevGames => [...prevGames, ...uniqueGames]);
            })
            .catch(error => console.error('Error fetching games:', error));
    };


    const handleShowMore = () => {
        setLimit(prevLimit => prevLimit + 10);
    };

    const handleLetsPlayClick = (path) => {
        if (sessionStorage.getItem('phoneNumber')) {
            window.location.href = path;
        } else {
            navigate('/login');
        }
    };

    return (
        <>
            <Navbar />
            <div className="hero-wrap js-fullheight" style={{ backgroundImage: 'url(/images/banner-4-fits.jpg)', height: 600 }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight" data-scrollax-parent="true" style={{ height: "100vh", display: "flex", alignItems: "center" }}>
                        <h1 className="mb-4" data-scrollax="properties: { translateY: '70%', opacity: 1.6 }">
                            <h1 className="mb-4" data-scrollax="properties: { translateY: '70%', opacity: 1.6 }"><strong>Explore<br />Top</strong><br /><strong>Games!</strong></h1>
                        </h1>
                        {/* <p data-scrollax="properties: { translateY: '30%', opacity: 1.6 }">
                            <h1 className="mb-4" data-scrollax="properties: { translateY: '70%', opacity: 1.6 }"><strong>Explore<br />Top</strong><br /><strong>Games!</strong></h1>
                        </p> */}
                        <p>
                            {/* Or browse our games by categories */}
                        </p>
                    </div>
                </div>
            </div >

            <section className="ftco-section ftco-destination">
                <div className="container">
                    <span className="subheading">Top</span>
                    <h2 className="mb-4"><strong>Games</strong> List</h2>
                    <div className="row">
                        {games.map((category, index) => (
                            <div className="col-md-3" key={category.id}>
                                <div className="item">
                                    <div className="destination">
                                        <a className="img d-flex justify-content-center align-items-center"
                                            style={{ backgroundImage: `url(${category.thumbnail ? category.thumbnail : '/images/banner-1-fits.jpg'})`, height: '300px', backgroundSize: 'cover' }}>
                                        </a>
                                        <div className="text p-3">
                                            <h3 style={{ minHeight: '3em', maxHeight: '3em', overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>{category.title}</h3>
                                            <div className="d-flex">
                                                <hr />
                                                <p className="truncate">{category.description}</p>
                                            </div>
                                            <hr />
                                            <p className="bottom-area d-flex">
                                                <span class="ml-auto"><a style={{ textDecoration: 'none' }} onClick={() => handleLetsPlayClick(category.path)}>Let's Play</a></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row mt-4">
                        <div className="col text-center">
                            <button className="btn btn-primary" onClick={handleShowMore}>Show More</button>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Games;
